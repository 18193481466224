import { MutationTree } from "vuex";

// Types
import { IRecommendationsState } from "@/store/recommendations/types";

export enum RecommendationsMutations {
  GET_RECOMMENDATIONS = "GET_RECOMMENDATIONS",
  GET_RECOMMENDATIONS_SUCCESS = "GET_RECOMMENDATIONS_SUCCESS",
  GET_RECOMMENDATIONS_FAILURE = "GET_RECOMMENDATIONS_FAILURE",
  ADD_RECOMMENDATIONS = "ADD_RECOMMENDATIONS",
  ADD_RECOMMENDATIONS_SUCCESS = "ADD_RECOMMENDATIONS_SUCCESS",
  ADD_RECOMMENDATIONS_FAILURE = "ADD_RECOMMENDATIONS_FAILURE",
}

const mutations: MutationTree<IRecommendationsState> = {
  [RecommendationsMutations.GET_RECOMMENDATIONS](state) {
    state.getListLoading = true;
    state.getListSuccess = false;
    state.getListFailure = {
      name: "",
      message: "",
    };
  },
  [RecommendationsMutations.GET_RECOMMENDATIONS_SUCCESS](state, payload) {
    state.list = payload;
    state.getListSuccess = true;
    state.getListLoading = false;
  },
  [RecommendationsMutations.GET_RECOMMENDATIONS_FAILURE](state) {
    state.getListFailure = {
      name: "Something went wrong",
      message: "Cannot get recommended providers",
    };
    state.getListLoading = false;
  },
  [RecommendationsMutations.ADD_RECOMMENDATIONS](state) {
    state.addRecommendationsLoading = true;
    state.addRecommendationsSuccess = false;
    state.addRecommendationsFailure = {
      name: "",
      message: "",
    };
  },
  [RecommendationsMutations.ADD_RECOMMENDATIONS_SUCCESS](state, payload) {
    state.list = payload;
    state.addRecommendationsSuccess = true;
    state.addRecommendationsLoading = false;
  },
  [RecommendationsMutations.ADD_RECOMMENDATIONS_FAILURE](state) {
    state.addRecommendationsFailure = {
      name: "Something went wrong",
      message: "Cannot add recommended providers",
    };
    state.addRecommendationsLoading = false;
  },
};

export default mutations;

import { MutationTree } from "vuex";

// Types
import { ISurveyAnswer, ISurveysState } from "@/store/surveys/types";

export enum SurveysMutations {
  SWITCH_ACTIVE_SURVEY = "CHANGE_ACTIVE_SURVEY",
  GET_SURVEYS_QUESTIONS = "GET_SURVEYS_QUESTIONS",
  GET_SURVEYS_QUESTIONS_SUCCESS = "GET_SURVEYS_QUESTIONS_SUCCESS",
  GET_SURVEYS_QUESTIONS_FAILURE = "GET_SURVEYS_QUESTIONS_FAILURE",
  GET_SURVEYS_ANSWERS = "GET_SURVEYS_ANSWERS",
  GET_SURVEYS_ANSWERS_SUCCESS = "GET_SURVEYS_ANSWERS_SUCCESS",
  GET_SURVEYS_ANSWERS_FAILURE = "GET_SURVEYS_ANSWERS_FAILURE",
  UPDATE_SURVEYS_ANSWERS = "UPDATE_SURVEYS_ANSWERS",
  UPDATE_SURVEYS_ANSWERS_SUCCESS = "UPDATE_SURVEYS_ANSWERS_SUCCESS",
  UPDATE_SURVEYS_ANSWERS_FAILURE = "UPDATE_SURVEYS_ANSWERS_FAILURE",
  GET_SURVEYS_MODAL = "GET_SURVEYS_MODAL",
  GET_SURVEYS_MODAL_SUCCESS = "GET_SURVEYS_MODAL_SUCCESS",
  GET_SURVEYS_MODAL_FAILURE = "GET_SURVEYS_MODAL_FAILURE",
}

const mutations: MutationTree<ISurveysState> = {
  [SurveysMutations.SWITCH_ACTIVE_SURVEY](state, payload) {
    state.activeSurveySlug = payload?.surveySlug;
  },
  [SurveysMutations.GET_SURVEYS_QUESTIONS](state) {
    state.getQuestionsLoading = true;
    state.getQuestionsSuccess = false;
    state.getQuestionsFailure = {
      name: "",
      message: "",
    };
  },
  [SurveysMutations.GET_SURVEYS_QUESTIONS_SUCCESS](state, payload) {
    state.questions = payload?.items;
    state.getQuestionsSuccess = true;
    state.getQuestionsLoading = false;
  },
  [SurveysMutations.GET_SURVEYS_QUESTIONS_FAILURE](state) {
    state.getQuestionsLoading = false;
  },
  [SurveysMutations.GET_SURVEYS_ANSWERS](state) {
    state.getAnswersLoading = false;
    state.getAnswersSuccess = false;
    state.getAnswersFailure = {
      name: "",
      message: "",
    };
  },
  [SurveysMutations.GET_SURVEYS_ANSWERS_SUCCESS](state, payload) {
    state.answers = payload?.items;
    state.getAnswersSuccess = true;
    state.getAnswersLoading = false;
  },
  [SurveysMutations.GET_SURVEYS_ANSWERS_FAILURE](state) {
    state.getAnswersLoading = false;
  },
  [SurveysMutations.UPDATE_SURVEYS_ANSWERS](state) {
    state.updateAnswersLoading = true;
    state.updateAnswersSuccess = false;
    state.updateAnswersFailure = {
      name: "",
      message: "",
    };
  },
  [SurveysMutations.UPDATE_SURVEYS_ANSWERS_SUCCESS](state, payload) {
    const filteredAnswers = state?.answers?.filter(
      (item: ISurveyAnswer) => item?.slug !== payload?.slug
    );
    state.answers = [...filteredAnswers, payload];
    state.updateAnswersSuccess = true;
    state.updateAnswersLoading = false;
  },
  [SurveysMutations.UPDATE_SURVEYS_ANSWERS_FAILURE](state) {
    state.updateAnswersFailure = {
      name: "",
      message: "",
    };
    state.updateAnswersLoading = false;
  },
  [SurveysMutations.GET_SURVEYS_MODAL](state, payload) {
    if (payload?.survey) state.activeSurveySlug = payload?.survey;
    state.providerId = payload?.providerId;
    state.providerPosition = payload?.providerPosition;
    state.getModalLoading = true;
    state.getModalSuccess = false;
    state.getModalFailure = {
      name: "",
      message: "",
    };
  },
  [SurveysMutations.GET_SURVEYS_MODAL_SUCCESS](state) {
    state.getModalSuccess = true;
    state.getModalLoading = false;
  },
  [SurveysMutations.GET_SURVEYS_MODAL_FAILURE](state) {
    state.getModalLoading = false;
  },
};

export default mutations;

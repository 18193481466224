// Types
import { IBinding } from "@/directives/types";

interface ITargetEl extends HTMLElement {
  clickOutsideEvent: (event: Event) => void;
}

export const clickOutSide = {
  mounted(el: ITargetEl, binding: IBinding) {
    el.clickOutsideEvent = (event: Event) => {
      if (!(el == event.target || el.contains(event.target as HTMLElement))) {
        binding.value(event, el);
      }
    };

    document.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el: ITargetEl) {
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};

export default clickOutSide;

// Types
import { ISurveysState } from "@/store/surveys/types";

const surveys = [
  {
    name: "Pricing",
    slug: "pricing",
    checkboxQuestions: {
      pricing_structure: ["Hourly", "Retainer Fee", "Flat Fee"],
    },
    rateQuestion: {
      label: "How would you rate their pricing structure overall?",
      variants: [
        "Very expensive",
        "Expensive",
        "Moderately expensive",
        "Affordable",
        "Very affordable",
      ],
    },
  },
  {
    name: "Communication",
    slug: "communication",
    rateQuestion: {
      label: "How would you rate their communication overall?",
      variants: [
        "Hard to reach",
        "Somewhat hard to reach",
        "Neutral",
        "Somewhat responsive",
        "Very responsive",
      ],
    },
  },
  {
    name: "Expertise & Experience",
    slug: "expertise_and_experience",
    rateQuestion: {
      label: "How would you rate their expertise overall?",
      variants: [
        "No confidence",
        "Little confidence",
        "Some confidence",
        "Good confidence",
        "Strong confidence",
      ],
    },
  },
  {
    name: "Strategy & Process",
    slug: "strategy_and_process",
    rateQuestion: {
      label: "How would you rate their strategy and process overall?",
      variants: [
        "No confidence",
        "Little confidence",
        "Some confidence",
        "Good confidence",
        "Strong confidence",
      ],
    },
  },
  {
    name: "Services & Deliverables",
    slug: "services_and_deliverables",
    rateQuestion: {
      label: "How would you rate their services and deliverables overall?",
      variants: [
        "Doesn’t meet needs",
        "Partially meets needs",
        "Mostly meets needs",
        "Meets needs",
        "Exceeds needs",
      ],
    },
  },
  {
    name: "Results & Reporting",
    slug: "results_and_reporting",
    rateQuestion: {
      label: "How would you rate their ability to deliver results overall?",
      variants: [
        "No confidence",
        "Little confidence",
        "Some confidence",
        "Good confidence",
        "Strong confidence",
      ],
    },
  },
  {
    name: "Your Rating",
    slug: "your_rating",
    rateQuestion: {
      label: "",
      variants: [],
    },
  },
];

const state: ISurveysState = {
  surveys,
  activeSurveySlug: "pricing",
  questions: [],
  getQuestionsLoading: false,
  getQuestionsSuccess: false,
  getQuestionsFailure: {
    name: "",
    message: "",
  },
  answers: [],
  getAnswersLoading: false,
  getAnswersSuccess: false,
  getAnswersFailure: {
    name: "",
    message: "",
  },
  updateAnswersLoading: false,
  updateAnswersSuccess: false,
  updateAnswersFailure: {
    name: "",
    message: "",
  },
  getModalLoading: false,
  getModalSuccess: false,
  getModalFailure: {
    name: "",
    message: "",
  },
  providerId: null,
  providerPosition: null,
};

export default state;

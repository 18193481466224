import * as Sentry from "@sentry/vue";
import { createApp, App as Application } from "vue";

// Components
import App from "@/App.vue";

// Directives
import vClickOutside from "@/directives/vClickOutside";

// Other
import store from "@/store";
import router from "@/router";
import CONSTANTS from "@/CONSTANTS";

// Styles
import "vue-style-guide/dist/vue-style-guide.css";

const shortlistApp: Application = createApp(App);

try {
  Sentry.init({
    app: shortlistApp,
    dsn: CONSTANTS.SENTRY_DSN,
    environment: CONSTANTS.SENTRY_ENVIRONMENT,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ["*"],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
} catch (err) {
  console.error("Something wrong: ", err);
}

shortlistApp.use(store);
shortlistApp.use(router);
shortlistApp.mount("#app");
shortlistApp.directive("click-outside", vClickOutside);

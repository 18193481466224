import { ActionTree } from "vuex";

// Mutations
import { ProvidersMutations } from "@/store/providers/mutations";

// Types
import { IRootState } from "@/store/types";
import { IProvidersState, IGetProvidersParams } from "@/store/providers/types";

// Other
import $axios from "@/utils/axios";

const actions: ActionTree<IProvidersState, IRootState> = {
  async getProviderStatuses({ commit }) {
    try {
      commit(ProvidersMutations.GET_PROVIDER_STATUSES);

      const result = await $axios.get("/shortlist/provider/status/list");

      commit(ProvidersMutations.GET_PROVIDER_STATUSES_SUCCESS, {
        items: result.data,
      });
    } catch (error) {
      commit(ProvidersMutations.GET_PROVIDER_STATUSES_FAILURE);
    }
  },

  async getProviders({ commit, rootState }, payload) {
    try {
      const params: IGetProvidersParams = {};

      commit(ProvidersMutations.GET_PROVIDERS);

      if (payload?.page) params.page = payload?.page;

      const result = await $axios.get(
        `/shortlist/${rootState?.shortlist?.key}/provider/list`,
        { params }
      );

      commit(ProvidersMutations.GET_PROVIDERS_SUCCESS, result.data);
    } catch (error) {
      commit(ProvidersMutations.GET_PROVIDERS_FAILURE);
    }
  },

  async removeProvider({ commit, rootState }, payload) {
    try {
      commit(ProvidersMutations.REMOVE_PROVIDER);

      await $axios.delete(
        `/shortlist/${rootState.shortlist.key}/provider/${payload.providerId}`
      );

      commit(ProvidersMutations.REMOVE_PROVIDER_SUCCESS, {
        providerId: payload.providerId,
      });
    } catch (error) {
      commit(ProvidersMutations.REMOVE_PROVIDER_FAILURE);
    }
  },

  async updateProvider(
    { commit, rootState },
    { note, statusSlug, providerId, isStatusUpdating }
  ) {
    try {
      const requestBody: {
        note?: string;
        status?: string;
      } = {};

      if (note) requestBody.note = note;
      if (statusSlug) requestBody.status = statusSlug;

      commit(ProvidersMutations.UPDATE_PROVIDER);

      const result = await $axios.put(
        `/shortlist/${rootState?.shortlist?.key}/provider/${providerId}`,
        requestBody
      );

      if (statusSlug) {
        const providers = rootState.providers.providers.map((item) => {
          if (item.provider_id === providerId) {
            return result.data;
          } else {
            return item;
          }
        });
        commit(ProvidersMutations.UPDATE_PROVIDER_STATUS_SUCCESS, providers);
      }
      commit(ProvidersMutations.UPDATE_PROVIDER_SUCCESS);
    } catch (error) {
      isStatusUpdating
        ? commit(ProvidersMutations.UPDATE_PROVIDER_FAILURE)
        : commit(ProvidersMutations.UPDATE_PROVIDER_STATUS_FAILURE);
    }
  },
};

export default actions;

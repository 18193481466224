import getMeta from "@/utils/getMeta";

const variables =
  process.env.NODE_ENV === "development"
    ? {
        SENTRY_DSN: "VUE_APP_SENTRY_DSN",
        SENTRY_ENVIRONMENT: "VUE_APP_SENTRY_ENVIRONMENT",
      }
    : {
        SENTRY_DSN: "sentryDNS",
        SENTRY_ENVIRONMENT: "sentryEnvironment",
      };

const userJSON = getMeta("user"),
  shortlistJSON = getMeta("shortlist");

let environment;

if (process.env.NODE_ENV === "development") {
  environment = "local";
} else if (window.location.hostname.includes("staging")) {
  environment = "staging";
} else {
  environment = "production";
}

const getVariable = (variableName: string) => {
  if (process.env.NODE_ENV === "development") return process.env[variableName];
  else return getMeta(variableName);
};

export default {
  ENVIRONMENT: environment,
  SHORTLIST: shortlistJSON ? JSON.parse(shortlistJSON) : {},
  USER: userJSON ? JSON.parse(userJSON) : {},
  SHORTLIST_COUNT: Number(getMeta("shortlistCount")),
  SENTRY_DSN: getVariable(variables.SENTRY_DSN),
  SENTRY_ENVIRONMENT: getVariable(variables.SENTRY_ENVIRONMENT),
  DOMAIN: environment !== "production" ? "staging." : "",
};

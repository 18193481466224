import { Module } from "vuex";

// State
import state from "@/store/providers/state";

// Actions
import actions from "@/store/providers/actions";

// Mutations
import mutations from "@/store/providers/mutations";

// Getters
import getters from "@/store/providers/getters";

// Types
import { IRootState } from "@/store/types";
import { IProvidersState } from "@/store/providers/types";

const providers: Module<IProvidersState, IRootState> = {
  state,
  mutations,
  actions,
  getters,
};

export default providers;

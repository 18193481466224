import axios from "axios";
import getCookie from "@/utils/getCookie";

const $axios = axios.create({
  baseURL: "/api/v1",
  withCredentials: true,
  headers: {
    "X-CSRFToken": getCookie("shortlist_csrftoken"),
  },
});

export default $axios;

import { ActionTree } from "vuex";

// Mutations
import { ProvidersMutations } from "@/store/providers/mutations";
import { RecommendationsMutations } from "@/store/recommendations/mutations";

// Types
import { IRootState } from "@/store/types";
import { IProvider } from "@/store/providers/types";
import {
  IRecommendedProvider,
  IRecommendationsState,
} from "@/store/recommendations/types";

// Other
import $axios from "@/utils/axios";

const actions: ActionTree<IRecommendationsState, IRootState> = {
  async getRecommendations({ commit, rootState }) {
    try {
      commit(RecommendationsMutations.GET_RECOMMENDATIONS);

      const result = await $axios.get(
        `/shortlist/${rootState.shortlist.key}/recommendations`
      );

      commit(
        RecommendationsMutations.GET_RECOMMENDATIONS_SUCCESS,
        result.data.providers
      );
    } catch (error) {
      commit(RecommendationsMutations.GET_RECOMMENDATIONS_FAILURE);
    }
  },

  async addRecommendations({ commit, rootState }, payload: Array<number>) {
    try {
      commit(RecommendationsMutations.ADD_RECOMMENDATIONS);

      // Remove ids of already added providers
      const filteredIds = payload.filter(
        (providerId: number) =>
          !rootState.providers.providers.some(
            (provider: IProvider) => provider.provider_id === providerId
          )
      );

      const result = await $axios.put(`/shortlist/provider/multiple`, {
        provider_ids: filteredIds,
      });

      // Update recommended providers
      const updatedRecommendations = rootState.recommendations.list.filter(
        (item: IRecommendedProvider) => !payload.includes(item.provider_id)
      );
      commit(
        RecommendationsMutations.ADD_RECOMMENDATIONS_SUCCESS,
        updatedRecommendations
      );

      // Update shortlist providers
      if (result.data && result.data.length) {
        const updatedProviders = [
          ...result.data,
          ...rootState.providers.providers,
        ];
        commit(ProvidersMutations.UPDATE_PROVIDERS_LIST, updatedProviders);
      }
    } catch (error) {
      commit(RecommendationsMutations.ADD_RECOMMENDATIONS_FAILURE);
    }
  },
};

export default actions;

import { IRecommendationsState } from "@/store/recommendations/types";

const state: IRecommendationsState = {
  list: [],
  getListLoading: false,
  getListSuccess: false,
  getListFailure: {
    name: "",
    message: "",
  },
  addRecommendationsLoading: false,
  addRecommendationsSuccess: false,
  addRecommendationsFailure: {
    name: "",
    message: "",
  },
};

export default state;

import { IProvidersState } from "@/store/providers/types";

const state: IProvidersState = {
  providerStatuses: [],
  getProviderStatusesLoading: false,
  getProviderStatusesSuccess: false,
  getProviderStatusesFailure: {
    name: "",
    message: "",
  },
  providers: [],
  nextProvidersPage: null,
  getProvidersLoading: false,
  getProvidersSuccess: false,
  getProvidersFailure: {
    name: "",
    message: "",
  },
  removeProviderLoading: false,
  removeProviderSuccess: false,
  removeProviderFailure: {
    name: "",
    message: "",
  },
  updateProviderLoading: false,
  updateProviderSuccess: false,
  updateProviderFailure: {
    name: "",
    message: "",
  },
  updateProviderStatusLoading: false,
  updateProviderStatusSuccess: false,
  updateProviderStatusFailure: {
    name: "",
    message: "",
  },
};

export default state;

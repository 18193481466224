import { MutationTree } from "vuex";

// Types
import {
  IProvider,
  IProviderRating,
  IProvidersState,
} from "@/store/providers/types";

export enum ProvidersMutations {
  GET_PROVIDER_STATUSES = "GET_PROVIDER_STATUSES",
  GET_PROVIDER_STATUSES_SUCCESS = "GET_PROVIDER_STATUSES_SUCCESS",
  GET_PROVIDER_STATUSES_FAILURE = "GET_PROVIDER_STATUSES_FAILURE",
  GET_PROVIDERS = "GET_PROVIDERS",
  GET_PROVIDERS_SUCCESS = "GET_PROVIDERS_SUCCESS",
  GET_PROVIDERS_FAILURE = "GET_PROVIDERS_FAILURE",
  REMOVE_PROVIDER = "REMOVE_PROVIDER",
  REMOVE_PROVIDER_SUCCESS = "REMOVE_PROVIDER_SUCCESS",
  REMOVE_PROVIDER_FAILURE = "REMOVE_PROVIDER_FAILURE",
  UPDATE_PROVIDER = "UPDATE_PROVIDER",
  UPDATE_PROVIDER_SUCCESS = "UPDATE_PROVIDER_SUCCESS",
  UPDATE_PROVIDER_FAILURE = "UPDATE_PROVIDER_FAILURE",
  UPDATE_PROVIDER_STATUS = "UPDATE_PROVIDER_STATUS",
  UPDATE_PROVIDER_STATUS_SUCCESS = "UPDATE_PROVIDER_STATUS_SUCCESS",
  UPDATE_PROVIDER_STATUS_FAILURE = "UPDATE_PROVIDER_STATUS_FAILURE",
  UPDATE_PROVIDER_RATING = "UPDATE_PROVIDER_RATING",
  UPDATE_PROVIDERS_LIST = "UPDATE_PROVIDERS_LIST",
}

const updateRatingPerSurvey = (
  ratingList: Array<IProviderRating>,
  updatedRating: IProviderRating
): Array<IProviderRating> =>
  ratingList?.map((rating: IProviderRating) =>
    rating?.survey === updatedRating?.survey ? updatedRating : rating
  );

const calculateOverallRating = (ratingList: Array<IProviderRating>): number => {
  const ratingNumbers = ratingList
    ?.map((item: IProviderRating) => item?.rating)
    ?.filter((number: number) => number > 0);

  if (!ratingNumbers.length) return 0;

  const ratingNumbersSum = ratingNumbers.reduce((a, b) => a + b);

  return Number((ratingNumbersSum / ratingNumbers.length).toFixed(1));
};

const mutations: MutationTree<IProvidersState> = {
  [ProvidersMutations.GET_PROVIDER_STATUSES](state) {
    state.getProviderStatusesLoading = true;
    state.getProviderStatusesSuccess = false;
    state.getProviderStatusesFailure = {
      name: "",
      message: "",
    };
  },
  [ProvidersMutations.GET_PROVIDER_STATUSES_SUCCESS](state, payload) {
    state.providerStatuses = payload?.items;
    state.getProviderStatusesSuccess = true;
    state.getProviderStatusesLoading = false;
  },
  [ProvidersMutations.GET_PROVIDER_STATUSES_FAILURE](state) {
    state.getProviderStatusesFailure = {
      name: "Something went wrong",
      message: "Cannot get provider statuses",
    };
    state.getProviderStatusesLoading = false;
  },
  [ProvidersMutations.GET_PROVIDERS](state) {
    state.getProvidersLoading = true;
    state.getProvidersSuccess = false;
    state.getProvidersFailure = {
      name: "",
      message: "",
    };
  },
  [ProvidersMutations.GET_PROVIDERS_SUCCESS](state, payload) {
    if (payload?.next_page) {
      state.nextProvidersPage = payload?.current_page + 1;
    } else {
      state.nextProvidersPage = null;
    }

    const newItems = payload?.items.filter(
      (newItem: IProvider) =>
        !state.providers.some(
          (item) => item?.provider_id === newItem?.provider_id
        )
    );

    state.providers = [...state.providers, ...newItems];
    state.getProvidersSuccess = true;
    state.getProvidersLoading = false;
  },
  [ProvidersMutations.GET_PROVIDERS_FAILURE](state) {
    state.getProvidersFailure = {
      name: "Something went wrong",
      message: "Cannot get providers. Please refresh the page",
    };
    state.getProvidersLoading = false;
  },
  [ProvidersMutations.REMOVE_PROVIDER](state) {
    state.removeProviderLoading = true;
    state.removeProviderSuccess = false;
    state.removeProviderFailure = {
      name: "",
      message: "",
    };
  },
  [ProvidersMutations.REMOVE_PROVIDER_SUCCESS](state, payload) {
    const updatedProviders = state?.providers.filter(
      (item) => item?.provider_id !== payload?.providerId
    );

    state.providers = updatedProviders;
    state.removeProviderSuccess = true;
    state.removeProviderLoading = false;
  },
  [ProvidersMutations.REMOVE_PROVIDER_FAILURE](state) {
    state.removeProviderFailure = {
      name: "Something went wrong",
      message: "Cannot remove this provider. Please try again",
    };
    state.removeProviderLoading = false;
  },
  [ProvidersMutations.UPDATE_PROVIDER](state) {
    state.updateProviderLoading = true;
    state.updateProviderSuccess = false;
    state.updateProviderFailure = {
      name: "",
      message: "",
    };
  },
  [ProvidersMutations.UPDATE_PROVIDER_SUCCESS](state) {
    state.updateProviderLoading = false;
    state.updateProviderSuccess = true;
  },
  [ProvidersMutations.UPDATE_PROVIDER_FAILURE](state) {
    state.updateProviderLoading = false;
    state.updateProviderFailure = {
      name: "",
      message: "",
    };
  },
  [ProvidersMutations.UPDATE_PROVIDER_STATUS](state) {
    state.updateProviderStatusLoading = true;
    state.updateProviderStatusSuccess = false;
    state.updateProviderStatusFailure = {
      name: "",
      message: "",
    };
  },
  [ProvidersMutations.UPDATE_PROVIDER_STATUS_SUCCESS](state, providers) {
    state.updateProviderStatusSuccess = true;
    state.updateProviderStatusLoading = false;
    state.providers = providers;
  },
  [ProvidersMutations.UPDATE_PROVIDER_STATUS_FAILURE](state) {
    state.updateProviderStatusFailure = {
      name: "Something went wrong",
      message: "Cannot update status. Please try again",
    };
    state.updateProviderStatusLoading = false;
  },
  [ProvidersMutations.UPDATE_PROVIDER_RATING](state, payload) {
    const updatedProviders = state?.providers?.map((provider: IProvider) => {
      if (provider?.provider_id === payload?.providerId) {
        const updatedRatingPerSurvey = updateRatingPerSurvey(
          provider?.rating_per_survey,
          {
            survey: payload?.survey,
            rating: payload?.rating,
          }
        );

        const updatedOverallRating = calculateOverallRating(
          updatedRatingPerSurvey
        );

        return {
          ...provider,
          overall_rating: updatedOverallRating,
          rating_per_survey: updatedRatingPerSurvey,
        };
      }

      return provider;
    });

    state.providers = updatedProviders;
  },
  [ProvidersMutations.UPDATE_PROVIDERS_LIST](state, payload) {
    state.providers = payload;
  },
};

export default mutations;

// Types
import { IShortlistState } from "@/store/shortlist/types";

// Other
import CONSTANTS from "@/CONSTANTS";

const state: IShortlistState = {
  canChange: CONSTANTS.SHORTLIST.can_change,
  name: CONSTANTS.SHORTLIST.name,
  key: CONSTANTS.SHORTLIST.key,
  count: CONSTANTS.SHORTLIST_COUNT,
  updateCountLoading: false,
  updateCountSuccess: false,
  updateCountFailure: {
    name: "",
    message: "",
  },
  renameLoading: false,
  renameSuccess: false,
  renameFailure: {
    name: "",
    message: "",
  },
  createLoading: false,
  createSuccess: false,
  createFailure: {
    name: "",
    message: "",
  },
};

export default state;

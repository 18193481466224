import { Module } from "vuex";

// State
import state from "@/store/shortlist/state";

// Actions
import actions from "@/store/shortlist/actions";

// Mutations
import mutations from "@/store/shortlist/mutations";

// Types
import { IRootState } from "@/store/types";
import { IShortlistState } from "@/store/shortlist/types";

const shortlist: Module<IShortlistState, IRootState> = {
  state,
  mutations,
  actions,
};

export default shortlist;

import { Module } from "vuex";

// State
import state from "@/store/recommendations/state";

// Actions
import actions from "@/store/recommendations/actions";

// Mutations
import mutations from "@/store/recommendations/mutations";

// Types
import { IRootState } from "@/store/types";
import { IRecommendationsState } from "@/store/recommendations/types";

const recommendations: Module<IRecommendationsState, IRootState> = {
  state,
  mutations,
  actions,
};

export default recommendations;

import { ActionTree } from "vuex";

// Mutations
import { ShortlistMutations } from "@/store/shortlist/mutations";

// Types
import { IRootState } from "@/store/types";
import { IShortlistState } from "@/store/shortlist/types";

// Other
import $axios from "@/utils/axios";

const actions: ActionTree<IShortlistState, IRootState> = {
  async createShortlist({ commit }) {
    try {
      commit(ShortlistMutations.CREATE_SHORTLIST);

      const result = await $axios.post(`/shortlist/new`, {
        name: "My Shortlist",
      });

      commit(ShortlistMutations.CREATE_SHORTLIST_SUCCESS, result.data);
    } catch (error) {
      commit(ShortlistMutations.CREATE_SHORTLIST_FAILURE);
    }
  },

  async renameShortlist({ commit }, payload) {
    try {
      commit(ShortlistMutations.RENAME_SHORTLIST);

      const result = await $axios.put(`/shortlist/${payload.key}`, {
        name: payload.name,
      });

      commit(ShortlistMutations.RENAME_SHORTLIST_SUCCESS, result.data);
    } catch (error) {
      commit(ShortlistMutations.RENAME_SHORTLIST_FAILURE);
    }
  },

  async updateShortlistCount({ commit }) {
    try {
      commit(ShortlistMutations.UPDATE_SHORTLIST_COUNT);

      // AJAX method to get updated count and rewrite shortlist counter in the header
      const count = await window.header?.updateShortlistCount();

      if (typeof count === "number") {
        commit(ShortlistMutations.UPDATE_SHORTLIST_COUNT_SUCCESS, count);
      }
    } catch (error) {
      commit(ShortlistMutations.UPDATE_SHORTLIST_COUNT_FAILURE);
    }
  },
};

export default actions;

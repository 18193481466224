import { MutationTree } from "vuex";

// Types
import { IShortlistState } from "@/store/shortlist/types";

export enum ShortlistMutations {
  RENAME_SHORTLIST = "RENAME_SHORTLIST",
  RENAME_SHORTLIST_SUCCESS = "RENAME_SHORTLIST_SUCCESS",
  RENAME_SHORTLIST_FAILURE = "RENAME_SHORTLIST_FAILURE",
  CREATE_SHORTLIST = "CREATE_SHORTLIST",
  CREATE_SHORTLIST_SUCCESS = "CREATE_SHORTLIST_SUCCESS",
  CREATE_SHORTLIST_FAILURE = "CREATE_SHORTLIST_FAILURE",
  UPDATE_SHORTLIST_COUNT = "UPDATE_SHORTLIST_COUNT",
  UPDATE_SHORTLIST_COUNT_SUCCESS = "UPDATE_SHORTLIST_COUNT_SUCCESS",
  UPDATE_SHORTLIST_COUNT_FAILURE = "UPDATE_SHORTLIST_COUNT_FAILURE",
}

const mutations: MutationTree<IShortlistState> = {
  [ShortlistMutations.RENAME_SHORTLIST](state) {
    state.renameLoading = true;
    state.renameSuccess = false;
    state.renameFailure = {
      name: "",
      message: "",
    };
  },
  [ShortlistMutations.RENAME_SHORTLIST_SUCCESS](state, payload) {
    state.name = payload?.name;
    state.renameSuccess = true;
    state.renameLoading = false;
  },
  [ShortlistMutations.RENAME_SHORTLIST_FAILURE](state) {
    state.renameFailure = {
      name: "Something went wrong",
      message: "Cannot rename the shortlist. Please try again",
    };
    state.renameLoading = false;
  },
  [ShortlistMutations.CREATE_SHORTLIST](state) {
    state.createLoading = true;
    state.createSuccess = false;
    state.createFailure = {
      name: "",
      message: "",
    };
  },
  [ShortlistMutations.CREATE_SHORTLIST_SUCCESS](state, payload) {
    state.name = payload?.name;
    state.key = payload?.key;
    state.createSuccess = true;
    state.createLoading = false;
  },
  [ShortlistMutations.CREATE_SHORTLIST_FAILURE](state) {
    state.createFailure = {
      name: "Something went wrong",
      message: "Cannot create the new shortlist. Please try again",
    };
    state.createLoading = false;
  },
  [ShortlistMutations.UPDATE_SHORTLIST_COUNT](state) {
    state.updateCountLoading = true;
    state.updateCountSuccess = false;
    state.updateCountFailure = {
      name: "",
      message: "",
    };
  },
  [ShortlistMutations.UPDATE_SHORTLIST_COUNT_SUCCESS](state, payload) {
    state.count = payload;
    state.updateCountSuccess = true;
    state.updateCountLoading = false;
  },
  [ShortlistMutations.UPDATE_SHORTLIST_COUNT_FAILURE](state) {
    state.updateCountFailure = {
      name: "Something went wrong",
      message: "Cannot update shortlist count",
    };
    state.updateCountLoading = false;
  },
};

export default mutations;

import { createStore } from "vuex";
import shortlist from "@/store/shortlist";
import providers from "@/store/providers";
import surveys from "@/store/surveys";
import recommendations from "@/store/recommendations";

const store = createStore({
  modules: {
    shortlist,
    providers,
    surveys,
    recommendations,
  },
});

export default store;

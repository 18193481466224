import { ActionTree } from "vuex";

// Mutations
import { SurveysMutations } from "@/store/surveys/mutations";

// Types
import { IRootState } from "@/store/types";
import { ISurveysState } from "@/store/surveys/types";

// Other
import $axios from "@/utils/axios";
import { ProvidersMutations } from "../providers/mutations";

const actions: ActionTree<ISurveysState, IRootState> = {
  async getSurveysQuestions({ commit }) {
    try {
      commit(SurveysMutations.GET_SURVEYS_QUESTIONS);

      const result = await $axios.get("/survey/list");

      commit(SurveysMutations.GET_SURVEYS_QUESTIONS_SUCCESS, {
        items: result.data,
      });
    } catch (error) {
      commit(SurveysMutations.GET_SURVEYS_QUESTIONS_FAILURE);
    }
  },

  async getSurveysAnswers({ commit, rootState }, payload) {
    try {
      commit(SurveysMutations.GET_SURVEYS_ANSWERS);

      const result = await $axios.get(
        `/shortlist/${rootState?.shortlist?.key}/provider/${payload?.providerId}/survey/list`
      );

      commit(SurveysMutations.GET_SURVEYS_ANSWERS_SUCCESS, {
        items: result.data,
      });
    } catch (error) {
      commit(SurveysMutations.GET_SURVEYS_ANSWERS_FAILURE);
    }
  },

  async updateSurveysAnswers({ commit, rootState }, payload) {
    try {
      commit(SurveysMutations.UPDATE_SURVEYS_ANSWERS);

      const result = await $axios.post(
        `/shortlist/${rootState.shortlist.key}/provider/${payload?.providerId}/survey/${payload?.surveySlug}`,
        payload?.requestBody
      );

      commit(SurveysMutations.UPDATE_SURVEYS_ANSWERS_SUCCESS, result?.data);
      commit(ProvidersMutations.UPDATE_PROVIDER_RATING, {
        providerId: payload?.providerId,
        survey: result?.data?.slug,
        rating: result?.data?.rate,
      });
    } catch (error) {
      commit(SurveysMutations.UPDATE_SURVEYS_ANSWERS_FAILURE);
    }
  },

  async getSurveysModal({ commit, state, dispatch }, payload) {
    try {
      if (payload?.surveySlug) {
        commit(SurveysMutations.SWITCH_ACTIVE_SURVEY, {
          surveySlug: payload?.surveySlug,
        });
      }

      commit(SurveysMutations.GET_SURVEYS_MODAL, payload);

      if (!state?.questions?.length) {
        await dispatch("getSurveysQuestions");
      }

      await dispatch("getSurveysAnswers", {
        providerId: payload?.providerId,
      });

      commit(SurveysMutations.GET_SURVEYS_MODAL_SUCCESS);
    } catch (error) {
      commit(SurveysMutations.GET_SURVEYS_MODAL_FAILURE);
    }
  },
};

export default actions;
